import RateDisplay from "@components/Cards/RateDisplay";
import useCurrency from "@lib/hooks/use-currency";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { newVsReturningAtom } from "atoms";
import { useAtom } from "jotai";
import { getCorrelation, getTotalValueOfAllRows } from "./calculations";
type Props<T> = {
  compared?: boolean;
} & (
  | {
      info: CellContext<T, number | undefined>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number | undefined>;
      calculateTotals: true;
    }
);

export type CurrencyCalculatorType = (
  value: number | undefined,
  numDigits: number,
  displaySymbol?: boolean
) => string;

const getDisplayString = (
  value: number,
  currencyCalculator: CurrencyCalculatorType
) => {
  return currencyCalculator(value, 2);
};

function CacTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
}: Props<T>) {
  const { calculateCurrencyConvertion } = useCurrency();
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const tooltip = (
    <Tooltip>
      <TooltipTrigger>-</TooltipTrigger>
      <TooltipContent>
        <p className="max-w-lg">
          This metric is only available with User Segmentation being active. You
          can do this via the User Segmentation Dropdown on the top
        </p>
      </TooltipContent>
    </Tooltip>
  );
  if (newVsReturning === "default") return tooltip;

  let comparedValue = 0;
  let value = 0;
  if (calculateTotals) {
    const allSpendValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value: row.original?.["spend"],
        compared: row.original?.compared?.["spend"],
      };
    });
    const allOrderValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value:
          row.original?.[
            newVsReturning === "new" ? "purchaseCount" : "nvr_purchaseCount"
          ],
        compared:
          row.original?.compared?.[
            newVsReturning === "new" ? "purchaseCount" : "nvr_purchaseCount"
          ],
      };
    });
    const spendTotals = getTotalValueOfAllRows({
      values: allSpendValues,
    });
    const orderTotals = getTotalValueOfAllRows({
      values: allOrderValues,
    });
    value =
      orderTotals.value && spendTotals.value
        ? spendTotals.value / orderTotals.value
        : 0;
    comparedValue =
      orderTotals.compared && spendTotals.compared
        ? spendTotals.compared / orderTotals.compared
        : 0;
  } else {
    value = info.getValue() ?? 0;
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }
  }

  const rowHasValue = value || comparedValue;
  if (!rowHasValue) return "-";
  const currentValueEl = getDisplayString(value, calculateCurrencyConvertion);

  if (compared) {
    const rate = getCorrelation(value, comparedValue, 2);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1">
          <p>{currentValueEl}</p>
          <p className="text-[11px] leading-tight opacity-70">
            vs {getDisplayString(comparedValue, calculateCurrencyConvertion)}
          </p>
        </div>
        <RateDisplay rate={rate} isIncreasing={rate < 0} size="small" />
      </div>
    );
  }
  return currentValueEl;
}

export default CacTableDisplay;
